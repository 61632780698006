import React, { useEffect } from "react";
import { graphql } from "gatsby";
import hljs from "highlight.js";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Tag from "../components/tag";


const PostTemplate = ({data}) => {

    const post = data.markdownRemark;
    const { title, date, desc, tags } = post.frontmatter;

    useEffect(() => {
        document.querySelectorAll("pre code").forEach(block => {
            hljs.highlightBlock(block);
        });
    });

    return(
        <Layout>
            <SEO title={title} description={desc} keywords={tags}/>
            <div className="post">
                <div className="head">/blog/*</div>

                <div className="post-date">{date}</div>
                <h1 className="post-title">{title}</h1>
                <span className="post-tags">{ tags.map(tag => (<Tag title={tag} />)) }</span>

                <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
        </Layout>
    );

} 
export default PostTemplate;

export const query = graphql`
query getPost($slug: String!) {
    markdownRemark(frontmatter: {slug: {eq: $slug} }) {
        frontmatter {
            title
            date(formatString: "DD MMMM YYYY")
            desc
            tags
        }
        html
    }
}
`;